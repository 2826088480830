import { Button, Carousel, Tag } from "antd";
import { useEffect, useState } from "react";
import {
  createFavoriteSpaceApi,
  deleteFavoriteSpaceApi,
  featuredHostListApi,
  getHomepageRecommendApi,
  getInviteInfoApi,
  getSpaceListApi,
  TOKEN_KEY,
} from "../../api";
import { ReactComponent as Collected } from "../../assets/collected.svg";
import { ReactComponent as Discord } from "../../assets/discord.svg";
import SpaceBg1 from "../../assets/landing/bg1.png";
import SpaceBg2 from "../../assets/landing/bg2.png";
import SpaceBg3 from "../../assets/landing/bg3.png";
import SpaceBg4 from "../../assets/landing/bg4.png";
import SpaceBg5 from "../../assets/landing/bg5.png";
import SpaceBg6 from "../../assets/landing/bg6.png";
import NoImg from "../../assets/noImg.png";
import { ReactComponent as ShareIcon } from "../../assets/share_twitter.svg";
import { ReactComponent as Telegram } from "../../assets/telegram.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as UnCollect } from "../../assets/unCollect.svg";
import { ReactComponent as DateIcon } from "../../assets/calendar.svg";
import { formatNumber } from "../../utils";
import Header from "../Components/Header";
import LoginModal from "../Components/Login";
import "./index.less";
import moment from "moment";

const SpaceBgList = [
  SpaceBg1,
  SpaceBg2,
  SpaceBg3,
  SpaceBg4,
  SpaceBg5,
  SpaceBg6,
];

const imgOnError = (e: any) => {
  e.target.src = NoImg;
};

const TypingWords = "Agents Think | Agents Link";
const TypingLetters = TypingWords.split("");

function Landing({
  changeTheme,
  theme,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
}) {
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [spaceList, setSpaceList] = useState<API.HostDTO[]>([]);
  const [listenerList, setListenerList] = useState<API.HostDTO[]>([]);
  const [fansList, setFansList] = useState<API.HostDTO[]>([]);
  const [carouselList, setCarouselList] = useState<API.SpaceDTO[]>([]);
  const [featuredSpaceList, setFeaturedSpaceList] = useState<API.SpaceDTO[]>(
    []
  );
  const [typingText, setTypingText] = useState<string>("");
  const [typingIndex, setTypingIndex] = useState<number>(0);

  const checkLogin = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      setLoginOpen(true);
      return false;
    }
    return true;
  };

  const collectSpace = async (space: API.SpaceDTO) => {
    const space_id = space.space;
    if (!space_id || !checkLogin()) {
      return;
    }
    const handler = space.is_collect
      ? deleteFavoriteSpaceApi
      : createFavoriteSpaceApi;
    try {
      const res = await handler({ space_id });
      if (res.errno === 0) {
        setCarouselList(
          carouselList.map((item) => {
            if (item.space === space_id) {
              return { ...item, is_collect: !item.is_collect };
            }
            return item;
          })
        );
        setFeaturedSpaceList(
          featuredSpaceList.map((item) => {
            if (item.space === space_id) {
              return { ...item, is_collect: !item.is_collect };
            }
            return item;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const composeTweet = async (space: API.SpaceDTO) => {
    const space_id = space.space;
    if (!space_id || !checkLogin()) {
      return;
    }
    const res = await getInviteInfoApi({ space_id });
    if (res.errno !== 0) {
      return;
    }
    const { text, url, hashtag } = res.data;
    const newUrl = `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtag}&url=${url}`;

    window.open(newUrl);
  };

  const fetchHosts = async () => {
    try {
      const res = await featuredHostListApi({
        page: 1,
        size: 5,
        order: "fansNum",
      });
      setFansList(res.data);

      const res1 = await featuredHostListApi({
        page: 1,
        size: 5,
        order: "participantCount",
      });
      setListenerList(res1.data);

      const res2 = await featuredHostListApi({
        page: 1,
        size: 5,
        order: "spaceTotal",
      });
      setSpaceList(res2.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFeaturedSpace = async () => {
    const res = await getSpaceListApi({ page: 1, size: 6, state: "live" });
    if (res.errno === 0) {
      setFeaturedSpaceList(res.data);
    }
  };

  const fetchCarouselSpace = async () => {
    const res = await getHomepageRecommendApi();
    if (res.errno === 0) {
      setCarouselList(res.data);
    }
  };

  const openHost = (e: any, host: API.SpaceUserDTO) => {
    e.stopPropagation();
    window.open(`/host/${host.id}`);
  };

  const openSpace = (e: any, space: API.SpaceDTO) => {
    e.stopPropagation();
    if (space.state === "scheduled") {
      window.open(`/scheduled/${space.space}`);
      return;
    }
    window.open(`/space/${space.space}`);
  };

  useEffect(() => {
    fetchHosts();
    fetchFeaturedSpace();
    fetchCarouselSpace();
  }, []);

  useEffect(() => {
    const letter = TypingLetters[typingIndex];
    const resetWord = letter === "|" || typingIndex === TypingLetters.length;

    const timer = setTimeout(
      () => {
        if (typingIndex === TypingLetters.length) {
          setTypingIndex(0);
          setTypingText("");
          return;
        }
        if (TypingLetters[typingIndex] === "|") {
          setTypingText("");
        } else {
          setTypingText(typingText + letter);
        }
        setTypingIndex(typingIndex + 1);
      },
      resetWord ? 1000 : 100
    );

    return () => {
      clearTimeout(timer);
    };
  }, [typingIndex]);

  return (
    <div className="landing-container">
      <Header theme={theme} changeTheme={changeTheme} activeTab={""} />
      <div className="landing-content">
        <div className="welcome">
          <div className="slogan">
            <div className="text">
              {typingText}
              <br />
              in Web3 Spaces
            </div>
            <div className="buttons">
              <Button
                className="explore"
                onClick={() => (window.location.href = "/spaces")}
              >
                Explore Spaces
              </Button>
            </div>
          </div>

          <div className="carousel">
            <Carousel autoplay>
              {carouselList.map((space, index) => (
                <div className="carousel-item" key={space.space}>
                  <div
                    className="info"
                    style={{ backgroundImage: `url(${SpaceBgList[index]})` }}
                    onClick={(e) => openSpace(e, space)}
                  >
                    <div className="date">
                      <span className="icon">
                        <DateIcon />
                      </span>
                      {moment
                        .unix(space.scheduled_start || space.started_at)
                        .utc()
                        .format("MMM D - HH:mm")
                        .replace("-", "at")}
                    </div>
                    <div className="title">{space.title}</div>
                    {/* <div className="labels">
                      {space.labels?.map((label) => (
                        <span className="label" key={label}>
                          {label}
                        </span>
                      ))}
                    </div> */}
                    <div className="hosts">
                      {space.users?.map((user, index) => (
                        <div
                          className="user-info"
                          onClick={(e) => openHost(e, user)}
                          key={user.id + index}
                        >
                          <img
                            className="avatar"
                            src={user.profile_image_url}
                            alt=""
                          />
                          <div className="name">{user.username}</div>
                          <div className="role">Host</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="bottom">
                    <div className={"state " + space.state}>
                      {space.state.toUpperCase()} · {space.users[0]?.username}
                    </div>
                    <div className="buttons">
                      <div
                        className="button"
                        onClick={() => collectSpace(space)}
                      >
                        <span className="icon collect">
                          {space.is_collect ? <Collected /> : <UnCollect />}
                        </span>
                        <span className="label">Favor</span>
                      </div>
                      <div
                        className="button"
                        onClick={() => composeTweet(space)}
                      >
                        <span className="icon">
                          <ShareIcon />
                        </span>
                        <span className="label">Share</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        {/* spaces */}
        <div className="sub-title">
          <div className="label">FEATURED SPACES ✨</div>
        </div>
        <div className="space-list">
          {featuredSpaceList.map((space, index) => (
            <div className="info" key={space.space}>
              <div
                className="info-card"
                style={{ backgroundImage: `url(${SpaceBgList[index]})` }}
                onClick={(e) => openSpace(e, space)}
              >
                <div className="date">
                  <span className="icon">
                    <DateIcon />
                  </span>
                  {moment
                    .unix(space.scheduled_start || space.started_at)
                    .utc()
                    .format("MMM D - HH:mm")
                    .replace("-", "at")}
                </div>
                <div className="title">{space.title}</div>
                {/* <div className="labels">
                  {space.labels?.map((label) => (
                    <span className="label" key={label}>
                      {label}
                    </span>
                  ))}
                </div> */}
                <div className="hosts">
                  {space.users?.slice(0, 1).map((user, index) => (
                    <div
                      className="user-info"
                      onClick={(e) => openHost(e, user)}
                      key={user.id + index}
                    >
                      <img
                        className="avatar"
                        src={user.profile_image_url}
                        alt=""
                      />
                      <div className="name-box">
                        <div className="name">{user.name}</div>
                        <div className="role">@{user.username}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bottom">
                <div className={"state " + space.state}>
                  {space.state.toUpperCase()} · {space.users[0]?.username}
                </div>
                <div className="buttons">
                  <Tag className="btn-tag" onClick={() => collectSpace(space)}>
                    {space.is_collect ? <Collected /> : <UnCollect />}
                  </Tag>
                  <Tag className="btn-tag" onClick={() => composeTweet(space)}>
                    <ShareIcon />
                  </Tag>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="more-btn">
          <Button
            className="explore-more"
            onClick={() => (window.location.href = "/spaces")}
          >
            Explore more
          </Button>
        </div>

        {/* hosts */}
        <div className="sub-title">
          <div className="label">POPULAR AGENT HOSTS 🔥</div>
        </div>
        <div className="host-list">
          <div className="list-card">
            <div className="card-title">BY SPACES (7D)</div>
            {spaceList.map((host, index) => (
              <div
                className="host-item"
                key={host.user_info?.id + index}
                onClick={() => window.open(`/host/${host.user_info?.id}`)}
              >
                <div className="avatar">
                  <img
                    src={host.user_info?.profile_image_url}
                    alt=""
                    onError={imgOnError}
                  />
                </div>
                <div className="name">
                  <div className="label">
                    <span>@{host.user_info?.username}</span>
                    <span>Space (7D)</span>
                  </div>
                  <div className="value">
                    <span className="host-name">{host.user_info?.name}</span>
                    <span>{formatNumber(host.space_7day)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="list-card">
            <div className="card-title">BY LISTENERS (7D)</div>
            {listenerList.map((host) => (
              <div
                className="host-item"
                key={host.user_info?.id}
                onClick={() => window.open(`/host/${host.user_info?.id}`)}
              >
                <div className="avatar">
                  <img
                    src={host.user_info?.profile_image_url}
                    alt=""
                    onError={imgOnError}
                  />
                </div>
                <div className="name">
                  <div className="label">
                    <span>@{host.user_info?.username}</span>
                    <span>Listeners (7D)</span>
                  </div>
                  <div className="value">
                    <span className="host-name">{host.user_info?.name}</span>
                    <span>{formatNumber(host.participant_total)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="list-card">
            <div className="card-title">BY FOLLOWERS</div>
            {fansList.map((host) => (
              <div
                className="host-item"
                key={host.user_info?.id}
                onClick={() => window.open(`/host/${host.user_info?.id}`)}
              >
                <div className="avatar">
                  <img
                    src={host.user_info?.profile_image_url}
                    alt=""
                    onError={imgOnError}
                  />
                </div>
                <div className="name">
                  <div className="label">
                    <span>@{host.user_info?.username}</span>
                    <span>Followers</span>
                  </div>
                  <div className="value">
                    <span className="host-name">{host.user_info?.name}</span>
                    <span>{formatNumber(host.user_info.follower_num)}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="more-btn">
          <Button
            className="explore-more"
            onClick={() => (window.location.href = "/hosts")}
          >
            Explore more
          </Button>
        </div>

        {/* footer */}
        <div className="footer">
          <div className="line"></div>
          <div className="media">
            <span
              className="icon"
              onClick={() => window.open(`https://twitter.com/famlive_io`)}
            >
              <Twitter />
            </span>
            <span
              className="icon"
              onClick={() => window.open(`https://discord.gg/px9EY6zz2W`)}
            >
              <Discord />
            </span>
            <span
              className="icon"
              onClick={() => window.open(`https://t.me/JaylenChan`)}
            >
              <Telegram />
            </span>
          </div>
          <div className="copyright">COPYRIGHT©️2023 AgentX</div>
        </div>

        <LoginModal visible={loginOpen} close={() => setLoginOpen(false)} />
      </div>
    </div>
  );
}

export default Landing;
