import {
  DownOutlined,
  LogoutOutlined,
  RightOutlined,
  TwitterOutlined,
  WalletOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Badge, Divider, Dropdown, Popover, Modal } from "antd";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  disconnectPlatformApi,
  getAccountInfoApi,
  TOKEN_KEY,
} from "../../../api/index";
import AirdropIcon from "../../../assets/airdrop/egg-icon.png";
import darkModeIcon from "../../../assets/dark_mode_black_24dp.svg";
import twitterLineIcon from "../../../assets/icon-twitter-line.svg";
import WalletLineIcon from "../../../assets/icon-wallet-line.svg";
import WalletIcon from "../../../assets/icon-wallet.png";
import lightModeIcon from "../../../assets/light_mode_black_24dp.svg";
import Logo from "../../../assets/logo.svg";
import LogoLight from "../../../assets/logo_light.svg";
import OpenInNew from "../../../assets/open_in_new_black.svg";
import TwitterLineIconLight from "../../../assets/twitter_line_light.svg";
import WalletIconLight from "../../../assets/wallet_light.svg";
import WalletLineIconLight from "../../../assets/wallet_line_light.svg";
import MetaMaskIcon from "../../../assets/metamask.svg";
import ArgentIcon from "../../../assets/argent.svg";
import okxIcon from "../../../assets/okx-logo.svg";
import { notify } from "../../../utils";
import {
  bindTwitterHandler,
  connectTwitterHandler,
  connectWalletHandler,
  openAddressByChainId,
  openStarknetAddress,
  connectArgentX,
} from "../../../utils/account";
import { Context } from "../../../utils/context";
import Bus from "../../../utils/eventBus";
import Balance from "./balance";
import "./index.less";
import HeaderSearch from "./search";

function Header({
  changeTheme,
  theme,
  activeTab,
  changeTab,
  showSlogan,
  hasLive,
}: {
  changeTheme: (theme: string) => void;
  theme: string;
  activeTab: string;
  changeTab?: (tab: string) => void;
  showSlogan?: boolean;
  hasLive?: boolean;
}) {
  const [twitterUser, setTwitterUser] = useState<string>("");
  const [walletUser, setWalletUser] = useState<string>("");
  const [isTwitterLogin, setIsTwitterLogin] = useState<boolean>(false);
  const [isWalletLogin, setIsWalletLogin] = useState<boolean>(false);
  const [loginType, setLoginType] = useState<string>("twitter");
  const [address, setAddress] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<string>("");
  const [twitterAvatar, setTwitterAvatar] = useState<string>("");
  const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false);
  const [walletType, setWalletType] = useState("");
  const [walletObject, setWalletObject] = useState<any>({});
  const navigate = useNavigate();
  const context = useContext(Context);
  const { userInfo } = context;

  const loginSuccess = (accountList: API.LoginOption[]) => {
    // reset user
    setIsWalletLogin(false);
    // setAddress("");
    // setWalletUser("");
    setIsTwitterLogin(false);
    setTwitterUser("");

    // set user
    if (!accountList || !Array.isArray(accountList)) {
      return;
    }
    const token = localStorage.getItem(TOKEN_KEY);
    const tokenInfo: any = jwtDecode(token || "");
    setLoginType(tokenInfo.ChainType);
    accountList.forEach((account) => {
      if (account.platform === "eth") {
        setIsWalletLogin(true);
        const addr = account.platform_id;
        const username = addr.slice(0, 6) + "..." + addr.slice(-4);
        setAddress(addr);
        setWalletUser(username);
      }
      if (account.platform === "twitter") {
        setIsTwitterLogin(true);
        setTwitterUser(account.username);
      }
    });
  };

  const changeCurrentTab = (tab: string) => {
    if (changeTab) {
      changeTab(tab);
    } else {
      window.location.href = `/${tab}`;
    }
  };

  const logout = () => {
    localStorage.clear();
    if (window.location.pathname === "/profile") {
      window.location.href = "/";
      return;
    }
    window.location.reload();
  };

  // const bindWallet = (callback?: () => void) => {
  //   bindWalletHandler(async () => {
  //     // fetch user info
  //     const token = localStorage.getItem(TOKEN_KEY) || "";
  //     const userInfo = await getAccountInfoApi(token);
  //     (window as any).walletUser =
  //       userInfo.nick || address.slice(0, 4) + "..." + address.slice(-4);
  //     const accountList = userInfo.extra.login_options;
  //     context.setUserInfo(userInfo);
  //     setTwitterAvatar(userInfo?.extra?.twitter_profile_icon);
  //     loginSuccess(accountList);
  //     callback?.();
  //   });
  // };
  const connectMetaMask = async () => {
    const metaMask = (window as any).ethereum;
    const accounts = await metaMask
      .request({ method: "eth_requestAccounts" })
      .catch((err: any) => {
        if (err.code === 4001) {
          // EIP-1193 userRejectedRequest error
          // If this happens, the user rejected the connection request.
          console.log("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      });
    setWalletUser(accounts[0].slice(0, 6) + "..." + accounts[0].slice(-4));
    setAddress(accounts[0]);
    setWalletType("metamask");
  };
  const connectOkxWallet = async () => {
    const OkxWallet = (window as any).okxwallet;
    const accounts = await OkxWallet.request({
      method: "eth_requestAccounts",
    }).catch((err: any) => {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.log("Please connect to Okx.");
      } else {
        console.error(err);
      }
    });
    setWalletUser(accounts[0].slice(0, 6) + "..." + accounts[0].slice(-4));
    setAddress(accounts[0]);

    setWalletType("okx");
  };

  const bindArgent = async () => {
    (window as any).connectArgent = true;
    try {
      const starknet = await connectArgentX();
      setWalletUser(
        `${starknet?.account.address.slice(
          0,
          6
        )}...${starknet?.account.address.slice(-4)}` || ""
      );
      setWalletType("argent");
      setAddress(starknet?.account.address || "");
    } catch (error) {
      console.log(error);
    }
  };

  const decodeToken = async () => {
    const { search, origin, pathname } = window.location;
    const params = new URLSearchParams(search);
    const urlToken = params.get("token");
    const errNo = params.get("errno");
    const errMsg = params.get("errmsg");
    // twitter
    if (errNo && errNo !== "0") {
      window.history.pushState("", "", `${origin}${pathname}`);
      notify({
        type: "warning",
        message: "",
        description: errMsg,
        longMessage: (errMsg?.length || 0) > 200,
      });
    }
    if (urlToken) {
      window.history.pushState("", "", `${origin}${pathname}`);
      localStorage.setItem(TOKEN_KEY, urlToken);
    }
    const token = urlToken || localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return;
    }
    Bus.emit("loginSuccess");
    const userInfo = await getAccountInfoApi(token);
    if (userInfo?.errno) {
      localStorage.removeItem(TOKEN_KEY);
      return;
    }
    const accountList = userInfo.extra.login_options;
    setTwitterAvatar(userInfo?.extra?.twitter_profile_icon);
    accountList && loginSuccess(accountList);
    (window as any).twitterUser = userInfo;

    // update context
    context.setUserInfo(userInfo);
    (window as any).loggedIn = !!userInfo.uid;
  };

  const openTwitter = (e: any) => {
    e.stopPropagation();
    window.open(`https://twitter.com/${twitterUser}`);
  };

  const openWallet = (e: any) => {
    e.stopPropagation();
    openAddressByChainId(address);
  };

  const disconnectPlatform = async (platform: "twitter" | "eth") => {
    if (walletUser && twitterUser) {
      const res = await disconnectPlatformApi(platform);
      if (res.data === "ok") {
        decodeToken();
      }
      return;
    }
    logout();
  };

  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    decodeToken();
    Bus.addListener("loginByAddress", () => {
      connectWalletHandler(decodeToken);
    });
    Bus.addListener("loginByTwitter", () => {
      connectTwitterHandler();
    });

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        decodeToken();
      }
    });

    return () => {
      Bus.removeAllListeners();
      window.removeEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          decodeToken();
        }
      });
    };
  }, []);

  const connectedToAllDevice = walletUser !== "" && twitterUser !== "";

  const userContent = (
    <div className="user-content">
      <div className="user-login-popover">
        <div className="user-connect">
          <div className="button-container">
            <img
              className="connect-button"
              src={
                twitterUser
                  ? twitterAvatar
                  : theme === "dark"
                  ? twitterLineIcon
                  : TwitterLineIconLight
              }
              alt="twitter"
              onClick={() => {
                if (!twitterUser && !walletUser) connectTwitterHandler();
                if (!twitterUser && walletUser) bindTwitterHandler();
              }}
            ></img>
          </div>
          {!twitterUser && <div className="login-tip">Connect Twitter</div>}
          {twitterUser && (
            <>
              <div className="user-info" onClick={openTwitter}>
                @{twitterUser}
              </div>
              <div className="button-container">
                {/* <div
                  className="disconnect"
                  onClick={() => disconnectPlatform("twitter")}
                >
                  Disconnect
                </div> */}
              </div>
            </>
          )}
        </div>
        {twitterUser && (
          <>
            <Divider
              type="vertical"
              style={{ height: "101px", margin: "16px 24px 0" }}
            ></Divider>
            <div className="user-connect">
              <div className="button-container">
                <img
                  className="connect-button"
                  src={
                    theme === "dark"
                      ? walletUser
                        ? WalletIcon
                        : WalletLineIcon
                      : walletUser
                      ? WalletIconLight
                      : WalletLineIconLight
                  }
                  alt="wallet"
                  onClick={() => {
                    if (!walletUser && !twitterUser)
                      connectWalletHandler(decodeToken);
                    if (!walletUser && twitterUser) {
                      setConnectModalOpen(true);
                    }
                  }}
                ></img>
              </div>
              {!walletUser && <div className="login-tip">Connect Wallet</div>}
              {walletUser && (
                <>
                  <div
                    className="user-info"
                    onClick={(e) => {
                      if (walletType !== "argent") {
                        openWallet(e);
                      } else openStarknetAddress(address);
                    }}
                  >
                    {walletUser}
                    <img className="open-icon" src={OpenInNew} alt="" />
                  </div>
                  <div className="button-container">
                    <div
                      className="disconnect"
                      onClick={() => {
                        setWalletUser("");
                        setWalletType("");
                      }}
                    >
                      Disconnect
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Divider style={{ margin: "0 0 24px 0" }}></Divider>
      {userInfo?.uid && (
        <div className="mode-switch" onClick={() => navigate("/profile")}>
          <span>My Profile</span>
          <RightOutlined style={{ fontSize: "18px" }} />
        </div>
      )}
      <div
        className="mode-switch"
        onClick={() => {
          if (theme === "dark") {
            changeTheme("light");
          } else changeTheme("dark");
        }}
      >
        {theme === "dark" ? "Light Mode" : "Dark Mode"}
        <img
          className="switch-icon"
          src={theme === "dark" ? lightModeIcon : darkModeIcon}
          alt="switch mode"
        />
      </div>
      {userInfo?.uid && (
        <div className="mode-switch logout" onClick={logout}>
          <div className="logout-btn">Logout</div>
          <LogoutOutlined style={{ fontSize: "18px" }} />
        </div>
      )}
    </div>
  );

  const tabs = [
    // { name: "Featured", value: "featured" },
    { name: "Agent X", value: "spaces" },
    { name: "Agent Arena", value: "hosts" },
    // { name: "AI-Summary", value: "summary" },
    { name: "Hive", value: "add" },
    // { name: "Airdrop", value: "airdrop" },
  ];

  const menus = [...tabs.map((t) => ({ label: t.name, key: t.value }))];

  return (
    <div className="home-header">
      <div className="top-bar">
        <div className="logo" onClick={() => (window.location.href = "/")}>
          <img src={theme === "dark" ? Logo : LogoLight} alt="" />
        </div>
        <div className="header-btn">
          {userInfo?.uid && (
            <Balance
              connectWallet={() => {
                setConnectModalOpen(true);
              }}
              theme={theme}
              walletType={walletType}
            />
          )}
          <div className="login">
            {!twitterUser ? (
              <div className="login-inner" onClick={connectTwitterHandler}>
                Login with Twitter
              </div>
            ) : (
              <Popover content={userContent} placement={"bottomRight"}>
                {connectedToAllDevice && (
                  <div
                    className="login-inner addr"
                    // onClick={loginType === "twitter" ? openTwitter : openWallet}
                  >
                    <WalletOutlined />
                    <TwitterOutlined />
                    <Divider type="vertical" />
                    <DownOutlined />
                  </div>
                )}
                {!connectedToAllDevice && (isTwitterLogin || isWalletLogin) && (
                  <div
                    className="login-inner addr"
                    // onClick={loginType === "twitter" ? openTwitter : openWallet}
                  >
                    {loginType === "twitter" ? (
                      <>
                        {isTwitterLogin ? (
                          <TwitterOutlined />
                        ) : (
                          <WalletOutlined />
                        )}
                        <span className="name">
                          {twitterUser || walletUser}
                        </span>
                      </>
                    ) : (
                      <>
                        {isWalletLogin ? (
                          <WalletOutlined />
                        ) : (
                          <TwitterOutlined />
                        )}
                        <span className="name">
                          {twitterUser || walletUser}
                        </span>
                      </>
                    )}
                    <Divider type="vertical" />
                    <DownOutlined />
                  </div>
                )}
                {!twitterUser && !walletUser && (
                  <div
                    className="login-inner"
                    // onClick={() => setLoginModalOpen(true)}
                  >
                    Login
                    <Divider type="vertical" />
                    <DownOutlined style={{ verticalAlign: "baseline" }} />
                  </div>
                )}
              </Popover>
            )}
          </div>
        </div>
        <div className="header-tabs">
          {tabs.map((tab) => (
            <div
              key={tab.value}
              className={`tab ${currentTab === tab.value && "active"}`}
              onClick={() => changeCurrentTab(tab.value)}
            >
              <Badge dot={tab.value === "hosts" && hasLive}>
                <span className="tab-text">{tab.name}</span>
              </Badge>
              {tab.value === "airdrop" && (
                <img className="tab-icon" src={AirdropIcon} alt="" />
              )}
            </div>
          ))}
          <HeaderSearch theme={theme} />
        </div>
        <div className="mobile-tabs">
          <Dropdown
            menu={{ items: menus, onClick: (t) => changeCurrentTab(t.key) }}
            trigger={["click"]}
          >
            <MenuOutlined />
          </Dropdown>
          <span className="currentTab">
            {tabs.find((t) => t.value === currentTab)?.name}
          </span>
        </div>
        <div className="line"></div>
        <Modal
          className="connect-modal"
          open={connectModalOpen}
          onCancel={() => {
            setConnectModalOpen(false);
          }}
          footer={null}
        >
          <div className="modal-title">Select Wallet</div>
          <div
            className="wallet-item"
            onClick={() => {
              setConnectModalOpen(false);
              connectMetaMask();
            }}
          >
            <img src={MetaMaskIcon} alt=""></img>
            MetaMask
          </div>
          <div
            className="wallet-item argent"
            onClick={() => {
              setConnectModalOpen(false);
              bindArgent();
            }}
          >
            <img src={ArgentIcon} alt=""></img>
            Argent X
          </div>
          <div
            className="wallet-item"
            onClick={() => {
              setConnectModalOpen(false);
              connectOkxWallet();
            }}
          >
            <img src={okxIcon} alt=""></img>
            OKX Wallet
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Header;
